<template>
  <div class="bodys">
    <div class="cont">
      
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_top" @click="$router.replace('/vipCenter/profile')">
            <img src="../../../assets/img/pro_fan.png" alt="" />
          </div>
          <div class="code1">
            <div class="code_name">资金密码</div>
            <input
              type="password"
              placeholder="请输入资金密码"
              maxlength="11"
              v-model="capitalValue"
              class="inp_code1"
            />
          </div>
          <div class="code1">
            <div class="code_name">确认资金密码</div>
            <input
              type="password"
              placeholder="请再次输入资金密码"
              maxlength="11"
              v-model="capitalValue2"
              class="inp_code1"
            />
          </div>
          <div class="code1">
            <div class="code_name">验证码</div>
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="code2Value"
              class="inp_code1"
            />
            <button type="info" v-show="show" @click="getCode" class="get_code">
              获取验证码
            </button>
            <button type="info" v-show="!show" class="get_code">
              重新发送({{ count }}s)
            </button>
          </div>
          <div class="code3">
            <div class="code_name">登录密码</div>
            <input
              type="password"
              placeholder="请输入登录密码"
              v-model="pwdValue"
              class="inp_code1"
            />
          </div>

          <div class="code3">
            <div class="code_name"></div>
            <button class="inp_btn" @click="onSubmit">确定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {changeMoneyPwd ,getVerifyCode } from '@network/setting'
export default {
  name: "Capital",
  data() {
    return {
      capitalValue: "", //资金密码
      capitalValue2: "", //资金密码2
      code2Value: "", //验证码
      pwdValue: "", //登录密码
      show: true, //取验证码
      count: "", //取验证码
      timer: null, //取验证码
      reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      code:'',
      exp:"",//手机验证码过期时间
      verify:"",
    };
  },
  created() {},
  components: {},
  methods: {
    onSubmit() {
      if (this.capitalValue != "" && this.capitalValue2 != "" && this.code2Value != "" && this.pwdValue !="") {
        if(this.capitalValue!= this.capitalValue2){
          this.$message.error({
              message: "两次密码输入不一致",
              offset: 300
            });
          return false;
        }
        changeMoneyPwd(this.exp,this.code2Value,this.verify,this.pwdValue,this.capitalValue2).then(res=>{
          console.log(res);
          this.$message({
              message: res.msg,
              offset: 300
            });
          if(res.code!=0){
            return false;
          }else{
            this.$router.replace('/vipCenter')
          }
        })
      } else {
        this.$message({
            message: "请填写内容",
            offset: 300
          });
      }
      
    },
    // 获取验证码
    getCode() {
      const TIME_COUNT = 180;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        getVerifyCode('fundPwd').then(res=>{
        console.log(res);
        this.$message({
            message: res.msg,
            offset: 300
          });
        if(res.code!=0){
          return false;
        }else{
          this.exp=res.data.exp;
          this.verify=res.data.verify;
        }
      })
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../assets/css/vipCenter/ChildComps/changePhone.less";
</style>
