import {request} from './request'

export function getVerifyCodeByPhone(phone,type){
    return request({
        url:'/getVerifyCode',
        params:{
            phone,type
        }
    })
}
//上传图片
export function imgUpload(file){
    let formData = new FormData()
    formData.append('file', file);
    return request({
        method:"POST",
        url:"/ext/imgUpload",
        data:formData
    })
}

//获取验证码
export function getVerifyCode(type){
    return request({
        url:"/mine/getVerifyCode",
        params:{
            type
        }
    })
}
//修改信息
export function update(headimg,sex,email,password,prov,city,area){
    return request({
        method:"post",
        url:"/mine/setInfo",
        data:{
            headimg:headimg,
            sex:sex,
            email:email,
            password:password,
            prov:prov,
            city:city,
            area:area,
        }
    })
}
//获取用户信息
export function getUserInfo(){
    return request({
        method:"get",
        url:"/mine/setInfo",
    })
}

//修改资金密码
export function changeMoneyPwd(exp,code,verify,password,fund_password){
    return request({
        method:"post",
        url:"/mine/setFundPwd",
        data:{
            exp,code,verify,password,fund_password
        }
    })
}

//修改手机号
export function changePhone(oldExp,oldCode,oldVerify,phone,exp,code,verify,password){
    return request({
        method:"post",
        url:"/mine/setPhone",
        data:{
            oldExp,oldCode,oldVerify,phone,exp,code,verify,password
        }
    })
}